
import CmsCardCarousel from '@/commoncomponents/CmsCardCarousel/CmsCardCarousel.vue';
import BreadcrumbComponent from '@/commoncomponents/breadcrumbComponent/BreadcrumbComponent.vue';
import MobileScreenUnavailable from '@/commoncomponents/mobileScreenUnavailable/MobileScreenUnavailable.vue';
import DeviceWidthCheckerMixin from '@/mixins/deviceWidthChecker.mixin';
import { Component, Mixins, Watch } from 'vue-property-decorator';


import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import APP_CONST from '@/constants/AppConst';
import { AlgoliaIndex } from '@/services/cms/algoliaService';
import {
  AlgoliaHitObject,
  algoliaToCardData,
  checkCMSEnabledReRoute,
  getCopyrightMessage
} from '@/utilities/cmsUtilities';
import APP_UTILITIES from '@/utilities/commonFunctions';
import accountListStore from "@/store/modules/accountsList";
import programListStore from "@/store/modules/programList";
import { getCourseCardStatus } from '@/utilities/cms/courseStatus';
import { sortCourseCards } from '@/utilities/cms/courseUtils';


@Component({
  components: {
    'bread-crumb': BreadcrumbComponent,
    'mobile-unavailable-screen': MobileScreenUnavailable,
    'card-carousel': CmsCardCarousel,
    'bouncing-preloader': BouncingPreloaderComponent
  }
})
export default class DashboardPage extends Mixins(DeviceWidthCheckerMixin) {
  loading: boolean = true;
  courses: Array<any> = [];

  isAdmin: boolean = false;
  subscriptionFilter: string = '';

  cmsLxEnhancementsEnabled = false;

  /* istanbul ignore next */
  get lxEnabled() {
    return programListStore.appSetting;
  }

  get subscriptionCategories() {
    return programListStore.cmsSubscriptionCategories;
  }

  async created() {
    this.cmsLxEnhancementsEnabled = await APP_UTILITIES.getFeatureFlag(APP_CONST.FEATURE_KEYS.cmsLxEnhancements);
  }

  @Watch('lxEnabled', { immediate: true, deep: true })
  cmsFeatureFlag(store: any) {
    if (checkCMSEnabledReRoute(store)) {
      this.$router.push({ path: '/' });
    }
  }

  getFilters() {
    const contentTypeFilter = '(contentType: "Course" AND displayOnMyContent:true)';
    const audienceFilter = `${this.isAdmin ? ' AND (audiences: "Admin")' : ' AND (audiences: "Staff")'}`;

    if (!this.subscriptionFilter) {
      return `${contentTypeFilter} ${audienceFilter}`;
    }

    return `${contentTypeFilter} ${audienceFilter} AND (${this.subscriptionFilter})`;
  }

  getSubscriptionFilter() {
    const subscriptionFilters = programListStore.cmsLicensesList.map(({ subscriptionName, subscriptionGrades }) => {
      const subscriptionNameFilter = `subscriptionNames: "${subscriptionName}"`;

      if (!this.cmsLxEnhancementsEnabled || subscriptionGrades.length === 0) {
        return subscriptionNameFilter;
      }

      return ['noGrades', ...subscriptionGrades]
        .map((grade) => `computedSubscriptionNames: "${subscriptionName}_${grade}"`)
        .join(' OR ');
    });

    return subscriptionFilters.join(' OR ');
  }

  get cmsCourseStatus() {
    return accountListStore.cmsCourseStatus;
  }


  async doSearch() {
    this.loading = true;

    if (this.subscriptionFilter.length === 0) {
      // Don't search before subscription filters are set
      return;
    }

    await AlgoliaIndex.search('', {
      facets: ['*'],
      filters: this.getFilters(),
      hitsPerPage: 1000
    }).then(({ hits }) => {
      const courseStatusStore = this.cmsCourseStatus;
      const cards = algoliaToCardData(hits as Array<AlgoliaHitObject>);
      const statusCards = getCourseCardStatus(cards, courseStatusStore);
      const sortedCards = sortCourseCards(statusCards);
      this.courses = sortedCards;
      this.loading = false;
    });
  }

  goToCatalog(filters?: any | undefined) {
    this.$router.push({
      path: APP_CONST.APP_ROUTES.CMS_CATALOG.path,
      query: filters ? filters : undefined
    });
  }

  async beforeMount() {
    await programListStore.setCmsSubscriptionData();
    this.subscriptionFilter = this.getSubscriptionFilter();
    const highestRole = Number(APP_UTILITIES.getCookie("highest_role"));
    this.isAdmin = highestRole !== null && highestRole < APP_CONST.SEVEN;
    accountListStore.setAccountCourseStatuses();
    this.$watch('cmsCourseStatus', this.doSearch, { deep: true });
  }

  mounted() {
    this.doSearch();
  }

  getCopyrightMessage = getCopyrightMessage;
}
